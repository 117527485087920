import React, { useState, useEffect } from "react";
import ".././Orders/Modal.css";
import axios from "axios";

const Modal = (props) => {
  const [disabled, setdisabled] = useState(true);
  const [locationdata, setLocationData] = React.useState([]);
  const [locationmasterdata, setLocationMasterData] = React.useState([]);
  const [countLoc, setCountLoc] = React.useState(true);
  const [countStatus, setCountStatus] = React.useState(true);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  useEffect(() => {
    axios
      .get(url + "/api/locations")
      .then(function (res) {
        setLocationMasterData(res.data);
        setLocationData(res.data);
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.location = "/Login?error=" + err.response.data.message;
        } else if (err.response.status === 403) {
          window.location =
            "/Login?error=" + "Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error=" + err.message;
        }
      });
  }, []);
  useEffect(() => {
    const getDestinationselectedValue = localStorage.getItem("sessionresultDest");
    const getStatusselectedValue = localStorage.getItem("sessionresultStatus");
    if (
      getDestinationselectedValue !== null &&
      getDestinationselectedValue !== ""
    ) {
      var filterval = getDestinationselectedValue.split(",");
      filterval.splice(-1);
      if (filterval !== "" && filterval !== null && filterval.length > 0) {
        for (var i = 0; i < filterval.length; i++) {
          let destinputs = document.getElementById(filterval[i] + "_");
          if (destinputs !== null) {
            if (countLoc) {
              setCountLoc(false);
              destinputs.checked = true;
              setdisabled(false);
            }
          }
        }
      }
    }
    if (getStatusselectedValue !== null && getStatusselectedValue !== "") {
      const filterstatusval = getStatusselectedValue.split(",");
      filterstatusval.splice(-1);
      if (
        filterstatusval !== "" &&
        filterstatusval !== null &&
        filterstatusval.length > 0
      ) {
        for (var j = 0; j < filterstatusval.length; j++) {
          let statusinputs = document.getElementById(filterstatusval[j] + "_");
          if (statusinputs !== null) {
            if (countStatus) {
              setCountStatus(false);
              statusinputs.checked = true;
              setdisabled(false);
            }
          }
        }
      }
    }
  });

  const ApplyFilters = () => {
    localStorage.removeItem("sessionresultDest");
    localStorage.removeItem("sessionresultStatus");
    localStorage.removeItem("sessionOrderPagePrevKey");
    const checkedDestination = document.getElementsByClassName("chkDest");
    const checkedStatus = document.getElementsByClassName("chkStatus");
    var resultDest = [];
    var resultStatus = [];
    for (var i = 0; i < checkedDestination.length; i++) {
      if (checkedDestination[i].checked) {
        resultDest += checkedDestination[i].value + ",";
      }
    }

    for (var j = 0; j < checkedStatus.length; j++) {
      if (checkedStatus[j].checked) {
        resultStatus += checkedStatus[j].value + ",";
      }
    }
    if (resultDest.length == 0 && resultStatus.length == 0) {
      alert("please select location or status.");
      return false;
    } else {
      localStorage.setItem("sessionresultDest", resultDest);
      localStorage.setItem("sessionresultStatus", resultStatus);
      //Set filters click count
      var getfiltercount = localStorage.getItem("sessionapplyfiltercount");
      if (getfiltercount == null) {
        getfiltercount = 0;
      }
      localStorage.setItem("sessionapplyfiltercount", getfiltercount + 1);
      props.onClose();
      setCountLoc(true);
      setCountStatus(true);
    }
  };

  //Changes performed for Clear filter options
  const ClearAll = () => {
    const inputs = document.getElementsByClassName("chkfilter");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    localStorage.removeItem("sessionresultDest");
    localStorage.removeItem("sessionresultStatus");
    localStorage.removeItem("sessionOrderPagePrevKey");
    //props.onClose();
  };

  function LoadLocations() {
    if (locationdata !== "" && locationdata !== undefined) {
      const LocationList = [];
      if (locationdata[0].afterFilterLoc !== undefined) {
        if (locationdata[0].afterFilterLoc.length > 0) {
          try {
            for (let i = 0; i < locationdata[0].afterFilterLoc.length; i++) {
              LocationList.push(
                <div className="row rowmodal">
                  <div className="col-10">
                    <label
                      className="custom-control-label"
                      htmlFor={locationdata[0].afterFilterLoc[i] + "_"}
                    >
                      {locationdata[0].afterFilterLoc[i]}
                    </label>
                  </div>
                  <div className="col">
                    <input
                      type="checkbox"
                      className="custom-control-input chkDest chkfilter"
                      id={locationdata[0].afterFilterLoc[i] + "_"}
                      value={locationdata[0].afterFilterLoc[i]}
                      onClick={() => {
                        setdisabled(false);
                      }}
                    />
                  </div>
                </div>
              );
            }
          } catch (err) {
            console.log(err.message);
          }
        }
      } else if (locationdata.length > 0) {
        try {
          for (let i = 0; i < locationdata.length; i++) {
            LocationList.push(
              <div className="row rowmodal">
                <div className="col-10">
                  <label
                    className="custom-control-label"
                    htmlFor={locationdata[i] + "_"}
                  >
                    {locationdata[i]}
                  </label>
                </div>
                <div className="col">
                  <input
                    type="checkbox"
                    className="custom-control-input chkDest chkfilter"
                    id={locationdata[i] + "_"}
                    value={locationdata[i]}
                    onClick={() => {
                      setdisabled(false);
                    }}
                  />
                </div>
              </div>
            );
          }
        } catch (err) {
          console.log(err.message);
        }
      }
      return LocationList;
    }
  }
  const funfiltermodal = () => {
    setCountLoc(true);
    setCountStatus(true);
    setLocationData(locationmasterdata);
    props.onClose();
  };
  const setData = () => {
    setLocationData(locationmasterdata);
  };

  function handleDestinationtextChange(event) {
    try {
      var filteredLocData = [];
      var bindFilterlocation = [];
      var afterFilterLoc = [];
      var destValue = event.target.value;
      destValue = destValue.toUpperCase();
      if (destValue !== "" && destValue !== null && destValue !== undefined) {
        if (locationmasterdata.length > 0) {
          bindFilterlocation.push({
            locations: locationmasterdata,
          });

          bindFilterlocation.forEach((element) => {
            afterFilterLoc = element.locations.filter((x) =>
              x?.toUpperCase().includes(destValue)
            );

            if (afterFilterLoc.length > 0) {
              filteredLocData.push({
                afterFilterLoc,
              });
            }
          });
        }
        setLocationData(null);
        setLocationData(filteredLocData);
      } else {
        setLocationData(locationmasterdata);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  if (!props.show) {
    return null;
  }

  return (
    <div className="modal" id="myModal">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Filter</h4>
          <button
            type="button"
            className="btn-close"
            onClick={funfiltermodal}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="row">
          <div className="form-group">
            <input
              type="text"
              className="input-lg form-control"
              name="Search Destination"
              id="txtSearchdest"
              placeholder="Search destination"
              onChange={handleDestinationtextChange}
            />
          </div>
        </div>
        <div className="modal-body">
          <h4 className="modal-title-inner">Destination</h4>
          <div className="divoverflow">{LoadLocations()}</div>

          <hr />
          <h4 className="modal-title-inner">Status</h4>
          <div className="divoverflow">
            <div className="row rowmodal">
              <div className="col-10">
                <label className="custom-control-label" htmlFor={"Generated_"}>
                  Generated
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"Generated_"}
                  value={"Generated"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label
                  className="custom-control-label"
                  htmlFor={"Driver Assigned_"}
                >
                  Driver Assigned
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"Driver Assigned_"}
                  value={"Driver Assigned"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label
                  className="custom-control-label"
                  htmlFor={"On Route To Terminal_"}
                >
                  On Route To Terminal
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"On Route To Terminal_"}
                  value={"On Route To Terminal"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label
                  className="custom-control-label"
                  htmlFor={"Loading Started_"}
                >
                  Loading Started
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"Loading Started_"}
                  value={"Loading Started"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label
                  className="custom-control-label"
                  htmlFor={"On Route To Delivery_"}
                >
                  On Route To Delivery
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"On Route To Delivery_"}
                  value={"On Route To Delivery"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label
                  className="custom-control-label"
                  htmlFor={"Arrived At Station_"}
                >
                  Arrived At Station
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"Arrived At Station_"}
                  value={"Arrived At Station"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label className="custom-control-label" htmlFor={"Completed_"}>
                  Completed
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"Completed_"}
                  value={"Completed"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
            <div className="row rowmodal">
              <div className="col-10">
                <label className="custom-control-label" htmlFor={"Cancelled_"}>
                  Cancelled
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkStatus chkfilter"
                  id={"Cancelled_"}
                  value={"Cancelled"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              onClick={() => {
                ClearAll();
                setdisabled(true);
              }}
              className="btn btn-outline-success btn-sm"
            >
              Clear All
            </button>
            <button
              id="btnApplyFilter"
              onClick={() => {
                ApplyFilters();
                setData();
              }}
              disabled={disabled}
              className="btn btn-outline-success btn-sm"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
