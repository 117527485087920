import React, { useState, useEffect } from "react";
import ".././Orders/Modal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Modal2 = (props) => {
  const [disabled, setdisabled] = React.useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [count, setCount] = React.useState(true);
  const [startDate, endDate] = dateRange;
  const pickermaxDate = new Date();
  pickermaxDate.setDate(pickermaxDate.getDate() + 1);

  useEffect(() => {
    const getHistoryselectedValue = localStorage.getItem("sessionHistory");
    const customDate = localStorage.getItem("sessionCustomHistory");
    if (getHistoryselectedValue !== null && getHistoryselectedValue !== "") {
      const filterHistoryval = getHistoryselectedValue.split(",");
      filterHistoryval.splice(-1);
      let historyinputs = document.getElementById(filterHistoryval[0] + "_");
      if (historyinputs !== null) {
        if (count) {
          setCount(false);
          if (filterHistoryval[0] !=="Custom") {
            historyinputs.checked = true;
          } else {
            if (customDate !== null && customDate !== "") {
              historyinputs.checked = true;
              showCustomDatePicker(customDate);
              document.getElementsByClassName("datepickercustom")[0].value =
                customDate;
              setdisabled(false);
            } else {
              historyinputs.checked = true;
              showCustomDatePicker();
            }
          }
        }
      }
    }
  });

  const ApplyStatusFilters = () => {
    localStorage.removeItem("sessionHistory");
    localStorage.removeItem("sessionCustomHistory");
    localStorage.removeItem("sessionOrderPagePrevKey");
    const rdbselected = document.getElementsByName("rdbHistory");
    //Set filters click count
    const getfiltercount = localStorage.getItem("sessionapplyfiltercount");
    if (getfiltercount == null) {
      getfiltercount = 0;
    }
    localStorage.setItem("sessionapplyfiltercount", getfiltercount + 1);
    for (let i of rdbselected) {
      if (i.checked) {
        localStorage.setItem("sessionHistory", i.value + ",");
        if (i.value == "Custom") {
          document.getElementById("dtCustomRange").style.border = "none";
          const selectedDate =
            document.getElementsByClassName("datepickercustom")[0].value;
          if (selectedDate !== "") {
            localStorage.setItem("sessionCustomHistory", selectedDate);
          } else {
            document.getElementById("dtCustomRange").style.border =
              "1px solid red";
            return false;
          }
        }
        setCount(true);
        props.onClose();
      }
    }
  };

  // Performed changes for clear filter options.
  const ClearAll = () => {
    const inputs = document.getElementsByClassName("chkHistory");
    document.getElementById("dtCustomRange").value = "";
    const dvDatepicker = document.getElementById("dvDatepicker");
    if (dvDatepicker) {
      dvDatepicker.style.display = "none";
    }
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    //added the below code for remove the selected items and close the modal.
    localStorage.removeItem("sessionHistory");
    localStorage.removeItem("sessionCustomHistory");
    localStorage.removeItem("sessionOrderPagePrevKey");
    setCount(true);
  };
  function showCustomDatePicker(date) {
    const chkYes = document.getElementById("Custom_");
    const dvDatepicker = document.getElementById("dvDatepicker");
    dvDatepicker.style.display = chkYes.checked ? "block" : "none";
    if (date !== "" && date !== null && date !== undefined) {
      document.getElementsByClassName("datepickercustom")[0].value = date;
    } else {
      document.getElementsByClassName("datepickercustom")[0].value = "";
    }
  }

  const funmodalclose = () => {
    setCount(true);
    props.onClose();
  };

  if (!props.modalshow) {
    return null;
  }
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Show History</h4>
          <button
            type="button"
            className="btn-close"
            onClick={funmodalclose}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <h4 className="modal-title-inner">Period</h4>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor="Today_">
                Today
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="rdbHistory"
                className="custom-control-input chkHistory"
                id="Today_"
                onClick={() => {
                  setdisabled(false);
                  showCustomDatePicker();
                }}
                value={"Today"}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor="Yesterday_">
                Yesterday
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="rdbHistory"
                className="custom-control-input chkHistory"
                id="Yesterday_"
                onClick={() => {
                  setdisabled(false);
                  showCustomDatePicker();
                }}
                value={"Yesterday"}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor="Last 7 Days_">
                Last 7 Days
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="rdbHistory"
                className="custom-control-input chkHistory"
                id="Last 7 Days_"
                onClick={() => {
                  setdisabled(false);
                  showCustomDatePicker();
                }}
                value={"Last 7 Days"}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor="Last 30 Days_">
                Last 30 Days
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="rdbHistory"
                className="custom-control-input chkHistory"
                id="Last 30 Days_"
                onClick={() => {
                  setdisabled(false);
                  showCustomDatePicker();
                }}
                value={"Last 30 Days"}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor="Custom_">
                Custom
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="rdbHistory"
                className="custom-control-input chkHistory"
                id="Custom_"
                onClick={() => {
                  setdisabled(false);
                  showCustomDatePicker();
                }}
                value={"Custom"}
              />
            </div>
          </div>
          <div id="dvDatepicker" className="row" style={{ display: "none" }}>
            <style>
              {`.react-datepicker {
          display: inline-flex;
      }`}
            </style>
            <div className="col-md-10">
              <DatePicker
                maxDate={pickermaxDate}
                wrapperClassName="react-datepicker"
                required
                id="dtCustomRange"
                placeholderText="Select date range"
                className="form-control datepickercustom"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat={"yyyy-MM-dd"}
                monthsShown={2}
                onChange={(update) => {
                  setDateRange(update);
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              ClearAll();
              setdisabled(true);
            }}
          >
            Clear All
          </button>
          <button
            id="btnFilter"
            onClick={() => ApplyStatusFilters()}
            className="btn btn-outline-success btn-sm"
            disabled={disabled}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
